import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import ParticleBackground from '@/components/common/ParticleBackground'
import Subtitle from '@/components/common/Subtitle'
import Typography from '@/components/common/Typography'

const InterviewCharge = props => {
  const { theme, body, ...other } = props
  return (
    <Root {...other}>
      <Background scale={400} />
      <Main>
        <Subtitle variant="h4" component="h3" color="primary.light">担当業務</Subtitle>
        <Body variant="body2">{body}</Body>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  padding: 36px 48px 48px;
  @media ${mq.and(tabletS)} {
    padding: 32px 24px 40px;
  }
  @media ${mq.and(mobile)} {
    padding: 28px 16px;
  }
`

const Background = styled(ParticleBackground)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Main = styled.div`
  position: relative;
`

const Body = styled(Typography)`
  margin-top: 20px;
  @media ${mq.and(mobile)} {
    margin-top: 12px;
  }
  font-weight: bold;
`

export default ThemeConsumer(InterviewCharge)
