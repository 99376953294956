import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { StaticQuery, graphql } from 'gatsby'
import { TweenMax, Power4 } from 'gsap/TweenMax'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { easing } from '@/utils/preset'
import Selector from './Selector'
import Charge from './Charge'
import Block from './Block'

/* eslint-disable no-unused-vars */
const plugins = [ScrollToPlugin]

class InterviewPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeIndex: 0,
      contentHeight: 'auto'
    }
    this.lastIndex = 0
  }
  componentDidUpdate (prevProps, prevState) {
    if (this.state.activeIndex !== prevState.activeIndex) {
      this.lastIndex = prevState.activeIndex
    }
  }
  handleSelectorOneChange = index => {
    this.setState({
      ...this.state,
      activeIndex: index
    })
  }
  handleSelectorTwoChange = index => {
    this.setState({
      ...this.state,
      activeIndex: index
    })
  }
  handleExit = node => {
    this.setState({
      ...this.state,
      contentHeight: `${node.clientHeight}px`
    })
  }
  handleEntering = node => {
    this.setState({
      ...this.state,
      contentHeight: `${node.clientHeight}px`
    })
    TweenMax.to(window, 1.2, { scrollTo: 0, ease: Power4.easeInOut })
  }
  handleEnter = node => {
  }
  handleEntered = node => {
    this.setState({
      ...this.state,
      contentHeight: 'auto'
    })
  }
  render () {
    return (
      <StaticQuery
        query={query}
        render={data => {
          const blocks = data.blocks.edges.map(el => el.node)
          const list = data.persons.edges
            .map(el => el.node)
            .map(item => {
              item.blocks = blocks.filter(block => {
                return item.number === block.frontmatter.personNumber
              })
              return item
            })
          return (
            <Root>
              <Selector list={list} activeIndex={this.state.activeIndex} handleChange={this.handleSelectorOneChange} />
              <TransitionGroup css={styles.TransitionGroup} style={{ height: this.state.contentHeight }}>
                {list
                  .filter((item, index) => index === this.state.activeIndex)
                  .map((item, index) => (
                    <CSSTransition
                      classNames="fade"
                      key={item.number}
                      timeout={{ enter: 800, exit: 800 }}
                      onExit={this.handleExit}
                      onEnter={this.handleEnter}
                      onEntering={this.handleEntering}
                      onEntered={this.handleEntered}
                    >
                      <Rows>
                        <Row><Charge body={item.charge} /></Row>
                        {item.blocks.map((block, index) => {
                          return (
                            <Row key={index}>
                              <Block {...block.frontmatter} html={block.html} />
                            </Row>
                          )
                        })}
                      </Rows>
                    </CSSTransition>
                  ))
                }
              </TransitionGroup>
              <Selector list={list} activeIndex={this.state.activeIndex} handleChange={this.handleSelectorTwoChange} />
            </Root>
          )
        }}
      />
    )
  }
}

const Root = styled.div``

const Rows = styled.div`
  &.fade-enter {
    position: absolute;
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 400ms ${easing.css.easeOutSine} 400ms;
  }
  &.fade-enter-done {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.fade-exit-active {
    opacity: 0;
    height: 0;
    transition:
      opacity 400ms ${easing.css.easeInSine};
  }
`

const Row = styled.div`
  @media ${mq.and(desktop, laptop, tabletL)} {
    padding: 40px 80px;
  }
  @media ${mq.and(tabletS)} {
    padding: 24px 0;
  }
  @media ${mq.and(mobile)} {
    padding: 24px 0;
  }
`

const styles = {
  TransitionGroup: css`
    position: relative;
    margin: 40px 0;
    @media ${mq.and(mobile)} {
      margin: 24px 0;
    }
    transition: height 400ms ${easing.css.easeOutSine};
    overflow: hidden;
  `
}

export default ThemeConsumer(InterviewPage)

export const query = graphql`
  query InterviewPageQuery {
    persons: allRecruitInterviewListYaml {
      edges {
        node {
          number
          year
          entry
          sex
          job
          charge
        }
      }
    }
    blocks: allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "recruit-interview-block" } } }
      sort: { fields: [frontmatter___personNumber, frontmatter___number], order: ASC }
    ) {
      edges {
        node {
          html
          frontmatter {
            postType
            personNumber
            number
            question
            keywords
          }
        }
      }
    }
  }
`
