import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import Section from '@/components/common/Section'
import Content from '@/components/common/Content'

const InterviewBlock = ({ theme, question, keywords, html, ...other }) => (
  <Root {...other}>
    <Section
      subtitle={question}
      title={keywords && keywords.length && (
        <Fragment>
          {keywords.map((keyword, index) => (
            <div key={index}>#{keyword}</div>
          ))}
        </Fragment>
      )}
      titleProps={{
        variant: 'h2'
      }}
    >
      <Main theme={theme}>
        <Content html={html} modifier="small"/>
      </Main>
    </Section>
  </Root>
)

const Root = styled.div`
`

const Main = styled.div`
`

export default ThemeConsumer(InterviewBlock)
