import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import Typography from '@/components/common/Typography'
import PersonButton from './PersonButton'

const onClick = (index, handler) => {
  return () => {
    handler(index)
  }
}

const InterviewPerson = ({ index, year, entry, sex, job, theme, active, handleClick, ...other }) => (
  <Root {...other}>
    <PersonButton active={active} theme={theme} onClick={onClick(index, handleClick)} />
    <Info variant="body3" component="h3" theme={theme}>
      {year} {entry}<br />
      {sex}・{job}
    </Info>
  </Root>
)

const getInfoColor = props => {
  if (props.type === 'contrast') {
    return props.theme.text.contrast
  } else {
    return props.theme.text.default
  }
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Info = styled(Typography)`
  margin-top: 12px;
  text-align: center;
  color: ${getInfoColor};
  transition: color 0.2s ease;
`

export default ThemeConsumer(InterviewPerson)
