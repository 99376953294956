import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mq } from '@/utils/helpers'
import { tabletS, mobile } from '@/utils/media'
import Person from './Person'

const InterviewSelector = ({ list, activeIndex, handleChange, ...other }) => (
  <Root {...other}>
    <List>
      <Parent>
        {list.map((person, index) => (
          <Child key={index}>
            <Person {...person} index={index} active={activeIndex === index} handleClick={handleChange} />
          </Child>
        ))}
      </Parent>
    </List>
  </Root>
)

const Root = styled.div`
`

const List = styled.div``

const Parent = styled.ul`
  padding: 0 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media ${mq.and(tabletS)} {
    padding: 0;
    justify-content: space-between;
  }
  @media ${mq.and(mobile)} {
    padding: 0;
    justify-content: space-between;
  }
  @media (max-width: 540px) {
    margin-top: -24px;
  }
`

const Child = styled.li`
  padding: 0 24px;
  @media ${mq.and(tabletS)} {
    padding: 0;
  }
  @media ${mq.and(mobile)} {
    padding: 0;
  }
  @media (max-width: 540px) {
    width: 50%;
    margin-top: 24px;
  }
`

export default ThemeConsumer(InterviewSelector)
