import React from 'react'
import styled from '@emotion/styled'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { fade } from '@material-ui/core/styles/colorManipulator'

const PersonButton = props => {
  const { theme, type, classes, children, className, active, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="outlined"
      classes={classes}
      {...other}
    >
      <Icon width="120" height="120" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg" active={active} theme={theme} >
        <Icon_Path d="M64.774 61.5826C75.6501 61.5826 84.4669 52.7658 84.4669 41.8897C84.4669 31.0136 75.6501 22.1968 64.774 22.1968C53.8979 22.1968 45.0811 31.0136 45.0811 41.8897C45.0811 52.7658 53.8979 61.5826 64.774 61.5826Z" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round"/>
        <Icon_Path d="M28.3057 117V106.803C28.3057 86.6728 44.6435 70.335 64.774 70.335C84.9046 70.335 101.242 86.6728 101.242 106.803V116" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
        <Icon_Path d="M128 64.5C128 99.5701 99.5701 128 64.5 128C29.4299 128 1 99.5701 1 64.5C1 29.4299 29.4299 1 64.5 1C99.5701 1 128 29.4299 128 64.5Z" strokeWidth="2"/>
      </Icon>
    </MuiButton>
  )
}

const getIconColor = props => {
  if (props.type === 'contrast') {
    return props.active
      ? props.theme.text.contrast
      : props.theme.scale[200]
  } else {
    return props.active
      ? props.theme.text.default
      : props.theme.scale[600]
  }
}

const Icon = styled.svg`
  color: ${getIconColor};
  transition: color 0.2s ease;
  cursor: pointer;
  @media ${mq.and(mobile)} {
    width: 100px;
    height: 100px;
  }
`

const Icon_Path = styled.path`
  stroke: currentColor;
`

const styles = props => {
  const color = getIconColor(props)
  return {
    root: {
      backgroundColor: 'transparent',
      color: color,
      borderColor: color,
      borderRadius: '50%',
      borderWidth: 0,
      width: 'auto',
      height: 'auto',
      padding: '0',
      fontSize: 0,
      fontFamily: '',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(color, 0.05),
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        },
        '&:disabled': {
          backgroundColor: 'transparent'
        }
      },
      '&:disabled': {
        color: fade(color, 0.6),
        borderColor: fade(color, 0.6)
      }
    }
  }
}

export default ThemeConsumer(WithStylesProps(styles)(PersonButton))
