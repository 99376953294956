import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-recruit.png'
import Page from '@/components/modules/Recruit/Interview/Page'
import CompanyLead from '@/components/modules/Recruit/Company/Lead'
import StatisticsLead from '@/components/modules/Recruit/Statistics/Lead'
import Entry from '@/components/modules/Recruit/Entry'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import LinkButton1 from '@/components/common/LinkButton1'

const RecruitStatisticsPage = props => (
  <Root>
    <SEO location={props.location} title="社員インタビュー | 採用情報" description="株式会社ウィザードの社員インタビュー。入社理由・会社の好きなところ・仕事内容など、数字だけではわからないウィザードの情報を、社員の声を通してお伝えします。"/>
    <Head>
      <PageTitle src={imgTitle}>社員インタビュー</PageTitle>
    </Head>
    <Main>
      <Row><Page /></Row>
      <Row css={styles.Company}><CompanyLead /></Row>
      <Row css={styles.Statistics}><StatisticsLead /></Row>
      <Row css={styles.Entry}><Entry /></Row>
      <Row css={styles.Link}><Button to="/recruit/" component={Link} scale={800}>採用トップページ</Button></Row>
      <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
      <Row css={styles.Contact}><ContactLead /></Row>
    </Main>
  </Root>
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const Button = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

const styles = {
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Company: css`
    padding: 0;
    margin-top: 32px;
    @media ${mq.and(mobile)} {
      padding: 0;
      margin-top: 24px;
    }
  `,
  Statistics: css`
    padding: 0;
    margin-top: 32px;
    @media ${mq.and(mobile)} {
      padding: 0;
      margin-top: 24px;
    }
  `,
  Entry: css`
    padding: 0;
    margin-top: 32px;
    @media ${mq.and(mobile)} {
      padding: 0;
      margin-top: 24px;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Link: css`
    text-align: center;
  `
}

export default PageBase(RecruitStatisticsPage)
